import type * as Stitches from '@stitches/react'
import React from 'react'

import { CSS, styled } from '../stitches.config'
import { sharedTypography } from '../utils/stitchesShared-utils'

// ~~~~ To do: Uncomment and check if it works for stitches >1.05 ~~~~//

// export const StyledText = styled('span', sharedTypography, {
//   // local reset
//   marginBottom: 0,
//   fontVariantNumeric: 'tabular-nums',
//   display: 'block',

//   variants: {
//     oll: {
//       // optimal line length
//       true: {
//         maxWidth: '75rem',
//       },
//       false: {},
//     },
//     allowNaturalMb: {
//       true: {},
//       false: { marginBottom: '0px' },
//     },
//   },

//   compoundVariants: [
//     {
//       allowNaturalMb: true,
//       variant: 'h1',
//       css: {
//         marginBottom: '$h1',
//       },
//     },
//     {
//       allowNaturalMb: true,
//       variant: 'h2',
//       css: {
//         marginBottom: '$h2',
//       },
//     },
//     {
//       allowNaturalMb: true,
//       variant: 'h3',
//       css: {
//         marginBottom: '$h3',
//       },
//     },
//     {
//       allowNaturalMb: true,
//       variant: 'h4',
//       css: {
//         marginBottom: '$h4',
//       },
//     },
//     {
//       allowNaturalMb: true,
//       variant: 'h5',
//       css: {
//         marginBottom: '$h5',
//       },
//     },
//     {
//       allowNaturalMb: true,
//       variant: 'h6',
//       css: {
//         marginBottom: '$h6',
//       },
//     },
//     {
//       allowNaturalMb: true,
//       variant: 'subtitle1',
//       css: {
//         marginBottom: '$subtitle1',
//       },
//     },
//     {
//       allowNaturalMb: true,
//       variant: 'subtitle2',
//       css: {
//         marginBottom: '$subtitle2',
//       },
//     },
//     {
//       allowNaturalMb: true,
//       variant: 'body1',
//       css: {
//         marginBottom: '$body1',
//       },
//     },
//     {
//       allowNaturalMb: true,
//       variant: 'body2',
//       css: {
//         marginBottom: '$body2',
//       },
//     },
//     {
//       allowNaturalMb: true,
//       variant: 'button',
//       css: {
//         marginBottom: '$button',
//       },
//     },
//     {
//       allowNaturalMb: true,
//       variant: 'caption',
//       css: {
//         marginBottom: '$caption',
//       },
//     },
//     {
//       allowNaturalMb: true,
//       variant: 'overline',
//       css: {
//         marginBottom: '$overline',
//       },
//     },
//     {
//       allowNaturalMb: true,
//       variant: 'blockquote',
//       css: {
//         marginBottom: '$blockquote',
//       },
//     },
//   ],
//   defaultVariants: {
//     oll: true,
//     allowNaturalMb: false,
//   },
// })

export const StyledText = styled('span', sharedTypography, {
  // local reset
  marginBottom: 0,
  fontVariantNumeric: 'tabular-nums',
  display: 'block',

  variants: {
    oll: {
      // optimal line length
      true: {
        maxWidth: '75rem',
      },
      false: {},
    },
    allowNaturalMb: {
      true: {},
      false: { marginBottom: '0px' },
    },
    isLink: {
      true: {
        display: 'inline-block',
        cursor: 'pointer',
        position: 'relative',
        userSelect: 'none',
        '&::before': {
          content: ' ',
          position: 'absolute',
          display: 'block',
          bottom: '0',
          width: '0%',
          height: '1px',
          transition: 'width 0.3s ease',
          backgroundColor: 'currentColor',
        },
        '&:hover': {
          '&::before': {
            width: '100%',
            transition: 'width 0.3s ease',
          },
        },
      },
    },
    variant: {
      h1: {
        textStyle: '$h1',
      },
      h2: {
        textStyle: '$h2',
      },
      h3: {
        textStyle: '$h3',
      },
      h4: {
        textStyle: '$h4',
      },
      h5: {
        textStyle: '$h5',
      },
      h6: {
        textStyle: '$h6',
      },
      subtitle1: {
        textStyle: '$subtitle1',
      },
      subtitle2: {
        textStyle: '$subtitle2',
      },
      body1: {
        textStyle: '$body1',
      },
      body2: {
        textStyle: '$body2',
      },
      button: {
        textStyle: '$button',
      },
      caption: {
        textStyle: '$caption',
      },
      overline: {
        textStyle: '$overline',
      },
      blockquote: {
        textStyle: '$blockquote',
      },
      code: {
        textStyle: '$code',
      },
    },
  },

  compoundVariants: [
    {
      allowNaturalMb: true,
      variant: 'h1',
      css: {
        marginBottom: '$h1',
      },
    },
    {
      allowNaturalMb: true,
      variant: 'h2',
      css: {
        marginBottom: '$h2',
      },
    },
    {
      allowNaturalMb: true,
      variant: 'h3',
      css: {
        marginBottom: '$h3',
      },
    },
    {
      allowNaturalMb: true,
      variant: 'h4',
      css: {
        marginBottom: '$h4',
      },
    },
    {
      allowNaturalMb: true,
      variant: 'h5',
      css: {
        marginBottom: '$h5',
      },
    },
    {
      allowNaturalMb: true,
      variant: 'h6',
      css: {
        marginBottom: '$h6',
      },
    },
    {
      allowNaturalMb: true,
      variant: 'subtitle1',
      css: {
        marginBottom: '$subtitle1',
      },
    },
    {
      allowNaturalMb: true,
      variant: 'subtitle2',
      css: {
        marginBottom: '$subtitle2',
      },
    },
    {
      allowNaturalMb: true,
      variant: 'body1',
      css: {
        marginBottom: '$body1',
      },
    },
    {
      allowNaturalMb: true,
      variant: 'body2',
      css: {
        marginBottom: '$body2',
      },
    },
    {
      allowNaturalMb: true,
      variant: 'button',
      css: {
        marginBottom: '$button',
      },
    },
    {
      allowNaturalMb: true,
      variant: 'caption',
      css: {
        marginBottom: '$caption',
      },
    },
    {
      allowNaturalMb: true,
      variant: 'overline',
      css: {
        marginBottom: '$overline',
      },
    },
    {
      allowNaturalMb: true,
      variant: 'blockquote',
      css: {
        marginBottom: '$blockquote',
      },
    },
  ],
  defaultVariants: {
    oll: true,
    allowNaturalMb: false,
    variant: 'body1',
    isLink: false,
  },
})

type TextProps = {
  onMouseDown?: (event: React.MouseEvent<HTMLSpanElement>) => void
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void
  children?: React.ReactNode
  css?: CSS
  className?: string
  as?: React.ElementType
} & Stitches.VariantProps<typeof StyledText>

const Text = React.forwardRef<HTMLSpanElement, TextProps>((props, forwardedRef) => (
  <StyledText {...props} ref={forwardedRef} />
))

Text.displayName = 'Text'
export { Text }
