import { THEME_TOKENS } from '@models/theme-types'

import { systemTokens } from './systemTokens'

export const pubgenLightThemeTokens = {
  ...systemTokens,
  colors: {
    none: 'transparent',
    // Primary
    pri: 'rgba(45,91,91,1)',
    priText: '$gs1',
    pri_90: 'rgba(45,91,91,0.90)',
    pri_L: 'rgba(63,128,128,1)',
    pri_D: 'rgba(40,82,82,1)',
    // Secondary
    sec: 'rgba(57,114,114,1)',
    secText: '$gs1',
    sec_90: 'rgba(57,114,114,0.90)',
    sec_L: 'rgba(68,137,137,1)',
    sec_D: 'rgba(51,103,103,1)',
    // Link
    lin: 'rgba(203,69,51,1)',
    linText: '$gs1',
    lin_90: 'rgba(203,69,51,0.90)',
    lin_L: 'rgba(209,87,71,1)',
    lin_D: 'rgba(183,62,46,1)',
    // lin: 'rgba(0,82,255,1)',
    // linText: '$gs1',
    // lin_90: 'rgba(0,82,255,0.90)',
    // lin_L: 'rgba(26,99,255,1)',
    // lin_D: 'rgba(0,74,230,1)',
    // Alert
    ale: 'rgba(179,58,58,1)',
    aleText: '$gs1',
    ale_90: 'rgba(179,58,58,0.90)',
    ale_L: 'rgba(199,78,78,1)',
    ale_D: 'rgba(159,38,38,1)',
    // Success
    suc: 'rgba(76,123,115,1)',
    sucText: '$gs1',
    suc_90: 'rgba(76,123,115,0.90)',
    suc_L: 'rgba(87,142,132,1)',
    suc_D: 'rgba(68,110,103,1)',
    // Warning
    war: 'rgba(151,96,7,1)',
    war_90: 'rgba(151,96,7,0.90)',
    warText: '$gs1',
    war_L: 'rgba(185, 117, 9,1)',
    war_D: 'rgba(136,86,6,1)',
    // Gray Scales
    gs1: '#fcfcfc',
    gs2: '#f8f8f8',
    gs3: '#f3f3f3',
    gs4: '#ededed',
    gs5: '#e8e8e8',
    gs6: '#e2e2e2',
    gs7: '#dbdbdb',
    gs8: '#c7c7c7',
    gs9: '#8f8f8f',
    gs10: '#858585',
    gs11: '#6f6f6f',
    gs12: '#171717',
    b1: 'rgba(0,0,0,0.916)',
    b2: 'rgba(0,0,0,0.833)',
    b3: 'rgba(0,0,0,0.750)',
    b4: 'rgba(0,0,0,0.666)',
    b5: 'rgba(0,0,0,0.583)',
    b6: 'rgba(0,0,0,0.50)',
    b7: 'rgba(0,0,0,0.416)',
    b8: 'rgba(0,0,0,0.333)',
    b9: 'rgba(0,0,0,0.250)',
    b10: 'rgba(0,0,0,0.167)',
    b11: 'rgba(0,0,0,0.083)',
    b12: 'rgba(0,0,0,0.000)',
    w1: 'rgba(255,255,255,1)',
    w2: 'rgba(255,255,255,0.9167)',
    w3: 'rgba(255,255,255,0.8334)',
    w4: 'rgba(255,255,255,0.6668)',
    w5: 'rgba(255,255,255,0.5835)',
    w6: 'rgba(255,255,255,0.5002)',
    w7: 'rgba(255,255,255,0.4169)',
    w8: 'rgba(255,255,255,0.3336)',
    w9: 'rgba(255,255,255,0.2503)',
    w10: 'rgba(255,255,255,0.167)',
    w11: 'rgba(255,255,255,0.0837)',
    w12: 'rgba(255,255,255,0.0004)',
    // Tokens for ui elements used in our system that users can customize.
    btnPriBg: '$pri',
    btnPriBo: '$priText',
    btnPriText: '$priText',
    btnPriBg_L: '$pri_L',
    btnPriBg_D: '$pri_D',
    btnSecBg: '$sec',
    btnSecBo: '$secText',
    btnSecText: '$secText',
    btnSecBg_L: '$sec_L',
    btnSecBg_D: '$sec_D',
    // btnPriBg: '$sec',
    // btnPriBo: '$secText',
    // btnPriText: '$secText',
    // btnPriBg_L: '$sec_L',
    // btnPriBg_D: '$sec_D',
    // btnSecBg: '$pri',
    // btnSecBo: '$priText',
    // btnSecText: '$priText',
    // btnSecBg_L: '$pri_L',
    // btnSecBg_D: '$pri_D',
    btnTerBg: '$gs12',
    btnTerBo: '$gs1',
    btnTerText: '$gs1',
    btnTerBg_L: '$gs11',
    btnTerBg_D: '$gs12',
    btnGhoBg: '$none',
    btnGhoBo: '$pri',
    btnGhoText: '$pri',
    btnGhoBg_L: '$gs4',
    btnGhoBg_D: '$gs5',
  },
  fonts: {
    h1: 'var(--font-family-nunito-serif), serif',
    h2: 'var(--font-family-nunito-serif), serif',
    h3: 'var(--font-family-nunito-serif), serif',
    h4: 'var(--font-family-nunito-serif), serif',
    h5: 'var(--font-family-nunito-serif), serif',
    h6: 'var(--font-family-nunito-serif), serif',
    subtitle1: 'var(--font-family-nunito-sans-serif), sans-serif',
    subtitle2: 'var(--font-family-nunito-sans-serif), sans-serif',
    body1: 'var(--font-family-nunito-serif), serif',
    body2: 'var(--font-family-nunito-sans-serif), sans-serif',
    button: 'var(--font-family-nunito-sans-serif), sans-serif',
    caption: 'var(--font-family-nunito-sans-serif), sans-serif',
    overline: 'var(--font-family-nunito-sans-serif), sans-serif',
    blockquote: 'var(--font-family-nunito-sans-serif), sans-serif',
    code: 'var(--font-family-nunito-sans-serif), sans-serif',
  },
  fontWeights: {
    h1: '400',
    h2: '400',
    h3: '400',
    h4: '400',
    h5: '400',
    h6: '400',
    subtitle1: '400',
    subtitle2: '400',
    body1: '400',
    body2: '400',
    button: '400',
    caption: '400',
    overline: '400',
    blockquote: '400',
    code: '400',
  },
  fontStyles: {
    h1: 'normal',
    h2: 'normal',
    h3: 'normal',
    h4: 'normal',
    h5: 'normal',
    h6: 'normal',
    subtitle1: 'normal',
    subtitle2: 'normal',
    body1: 'normal',
    body2: 'normal',
    button: 'normal',
    caption: 'normal',
    overline: 'normal',
    blockquote: 'normal',
    code: 'normal',
  },
  fontCases: {
    h1: 'none',
    h2: 'none',
    h3: 'none',
    h4: 'none',
    h5: 'none',
    h6: 'uppercase',
    subtitle1: 'none',
    subtitle2: 'none',
    body1: 'none',
    body2: 'none',
    button: 'capitalize',
    caption: 'none',
    overline: 'uppercase',
    blockquote: 'none',
    code: 'none',
  },
  fontSizes: {
    // 1rem = 10px
    h1: '8rem',
    h2: '6rem',
    h3: '4rem',
    h4: '3rem',
    h5: '2.5rem',
    h6: '1.8rem',
    subtitle1: '2.5rem',
    subtitle2: '2.2rem',
    body1: '2.0rem',
    body2: '1.5rem',
    button: '1.6rem',
    caption: '1.2rem',
    overline: '1.1rem',
    blockquote: '2.5rem',
    code: '1.2rem',
  },
  spacesAfter: {
    h1: '0.47em',
    h2: '0.5em',
    h3: '0.57em',
    h4: '0.6em',
    h5: '0.65em',
    h6: '0.7em',
    subtitle1: '0.68em',
    subtitle2: '0.45em',
    body1: '0.65em',
    body2: '0.73rem',
    button: '0em',
    caption: '0.4em',
    overline: '0.8em',
    blockquote: '0.88em',
    code: '0.4em',
    ...systemTokens.spacesAfter,
  },
  lineHeights: {
    h1: '1.1em',
    h2: '1.2em',
    h3: '1.4em',
    h4: '1.2em',
    h5: '1.4em',
    h6: '1.4em',
    subtitle1: '1.4em',
    subtitle2: '1.4em',
    body1: '1.6em',
    body2: '1.5em',
    button: '1.5em',
    caption: '1.4em',
    overline: '1.4em',
    blockquote: '1.4em',
    code: '1.4em',
  },
  letterSpacings: {
    h1: '0em',
    h2: '0em',
    h3: '0em',
    h4: '0em',
    h5: '0em',
    h6: '0.03em',
    subtitle1: '0em',
    subtitle2: '0em',
    body1: '0em',
    body2: '0em',
    button: '0em',
    caption: '0em',
    overline: '0.06em',
    blockquote: '0em',
    code: '0em',
  },
  borderWidths: {
    btnPri: '0px',
    btnSec: '0px',
    btnTer: '1px',
    btnGho: '0px',
  },
  borderStyles: {
    btnPri: 'solid',
    btnSec: 'solid',
    btnTer: 'solid',
    btnGho: 'none',
  },
  shadows: {
    bottom: '0 0.125rem 0.125rem -0.0625rem rgba(0,0,0,0.8)',
    around: 'rgba(31, 33, 36, 0.1) 0px 2px 4px 0px, rgba(31,33,36,0.05) 0px 1px 6px 0px ',
    card: '0 0 0.3125rem #c7c7c7,0 0.0625rem 0.125rem #e2e2e2',
  },
} satisfies THEME_TOKENS

export const htmlTemplateStylesForCMS = {
  colorGs12: pubgenLightThemeTokens.colors.gs12,
  colorGs11: pubgenLightThemeTokens.colors.gs11,
  colorGs3: pubgenLightThemeTokens.colors.gs3,
  colorGs1: pubgenLightThemeTokens.colors.gs1,
  colorSec: pubgenLightThemeTokens.colors.sec,
  colorSecText: pubgenLightThemeTokens.colors[pubgenLightThemeTokens.colors.secText],
  colorSec_L: pubgenLightThemeTokens.colors.sec_L,
  colorB10: pubgenLightThemeTokens.colors.b10,
  logoSrc: 'https://app-dev.pubgen.ai/_next/static/media/pubgenLogo_dark.0053a777.svg',
  ftzBody1: pubgenLightThemeTokens.fontSizes.body1,
  ftzBody2: pubgenLightThemeTokens.fontSizes.body2,
  ftzCaption: pubgenLightThemeTokens.fontSizes.caption,
  lineHeightCaption: pubgenLightThemeTokens.lineHeights.caption,
  lineHeightBody1: pubgenLightThemeTokens.lineHeights.body1,
  lineHeightBody2: pubgenLightThemeTokens.lineHeights.body2,
}
