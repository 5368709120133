export const systemTokens = {
  borderWidths: {
    0: '0px',
    1: '1px',
    2: '2px',
    3: '4px',
    4: '8px',
    5: '16px',
    thin: '1px',
  },
  transitions: {
    slow: '500ms',
    normal: '300ms',
    fast: '150ms',
  },
  zIndices: {
    1: '100',
    2: '200',
    3: '300',
    4: '400',
    max: '999',
  },
  space: {
    0: '0px',
    1: '4px',
    2: '8px',
    3: '12px',
    4: '16px',
    5: '20px',
    6: '24px',
    7: '28px',
    8: '32px',
    9: '36px',
    10: '40px',
    11: '44px',
    12: '48px',
    13: '52px',
    14: '56px',
    15: '60px',
    16: '64px',
    17: '68px',
    18: '72px',
    19: '76px',
    20: '80px',
    21: '84px',
    22: '88px',
  },
  sizes: {
    0: '0px',
    1: '4px',
    2: '8px',
    3: '12px',
    4: '16px',
    5: '20px',
    6: '24px',
    7: '28px',
    8: '32px',
    9: '36px',
    10: '40px',
    11: '44px',
    12: '48px',
    13: '52px',
    14: '56px',
    15: '60px',
    16: '64px',
    17: '68px',
    18: '72px',
    19: '76px',
    20: '80px',
    21: '84px',
    22: '88px',
  },
  radii: {
    0: '0px',
    1: '1px',
    2: '2px',
    3: '4px',
    4: '8px',
    5: '16px',
    6: '32px',
    round: '50%',
    pill: '9999px',
    btnPriTL: '4px',
    btnPriTR: '4px',
    btnPriBL: '4px',
    btnPriBR: '4px',
    btnSecTL: '4px',
    btnSecTR: '4px',
    btnSecBL: '4px',
    btnSecBR: '4px',
    btnTerTL: '4px',
    btnTerTR: '4px',
    btnTerBL: '4px',
    btnTerBR: '4px',
    btnGhoTL: '4px',
    btnGhoTR: '4px',
    btnGhoBL: '4px',
    btnGhoBR: '4px',
    inputTL: '10px',
    inputTR: '10px',
    inputBL: '10px',
    inputBR: '10px',
  },
  spacesAfter: {
    0: '0px',
    1: '4px',
    2: '8px',
    3: '12px',
    4: '16px',
    5: '20px',
    6: '24px',
    7: '28px',
    8: '32px',
    9: '36px',
    10: '40px',
    11: '44px',
    12: '48px',
    13: '52px',
    14: '56px',
    15: '60px',
    16: '64px',
    17: '68px',
    18: '72px',
    19: '76px',
    20: '80px',
  },
}
